import { comma, roundX } from '../utils'

const PRICING_LV1 = { lv: 1, text: '級距一', cost: 48 }
const PRICING_LV2 = { lv: 2, text: '級距二', cost: 46 }
const PRICING_LV3 = { lv: 3, text: '級距三', cost: 44 }

export const PRICING_LVS = [
  PRICING_LV1,
  PRICING_LV2,
  PRICING_LV3
]

export const toPiece = v => comma(roundX(v)) + ' 件'

export const toTwd = v => comma(roundX(v)) + ' 元'

export const PRICING_LABELS = [30, 150, 300, 600].map(value => ({
  text: toPiece(value),
  value
}))

/**
 * 級距一 運費48元  ->  第 1-30 件
 * 級距二 運費46元  ->  第 31-300 件
 * 級距三 運費44元  ->  第 301 件 以上
 * 如有申請上收服務，費用另計為每件 10 元
 */
export const getPricingLvs = count => {
  if (count > 300) {
    const rest = count - 300
    const fee = rest * PRICING_LV3.cost
    return [
      ...getPricingLvs(300),
      { ...PRICING_LV3, count: rest, fee }
    ]
  }
  if (count > 30) {
    const rest = count - 30
    const fee = rest * PRICING_LV2.cost
    return [
      ...getPricingLvs(30),
      { ...PRICING_LV2, count: rest, fee }
    ]
  }
  const fee = count * PRICING_LV1.cost
  return [{ ...PRICING_LV1, count, fee }]
}
